import { Routes } from "@angular/router";

import { AuthGuard } from "./shared";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./dashboard/dashboard.module#DashboardModule",
        canActivate: [AuthGuard],
      },
      {
        path: "user-profile/:id",
        loadChildren: "./userpage/user.module#UserModule",
        canActivate: [AuthGuard],
      },
      {
        path: "user-management",
        loadChildren: "./user-management/user-management.module#UserManagementModule",
        canActivate: [AuthGuard],
      },
      {
        path: "tunings",
        loadChildren: "./tunings/tunings.module#Tunings",
        canActivate: [AuthGuard],

        // children:[
        //   {
        //     path: "vin",
        //     loadChildren: "./tunings/tunings.module#Tunings/#vin",
        //     canActivate: [AuthGuard],
        //   },
        //   {
        //     path: "vin",
        //     loadChildren: "./tunings/tunings.module#Tunings/#vin",
        //     canActivate: [AuthGuard],
        //   },
        //   {
        //     path: "vin",
        //     loadChildren: "./tunings/tunings.module#Tunings/#vin",
        //     canActivate: [AuthGuard],
        //   },
        //   {
        //     path: "vin",
        //     loadChildren: "./tunings/tunings.module#Tunings/#vin",
        //     canActivate: [AuthGuard],
        //   },
        //   {
        //     path: "vin",
        //     loadChildren: "./tunings/tunings.module#Tunings/#vin",
        //     canActivate: [AuthGuard],
        //   }
        // ]
      },
      {
        path: "tuning-management",
        loadChildren: "./tuning-management/tuning-management.module#TuningManagementModule",
        canActivate: [AuthGuard],
      },
      {
        path: "packages",
        loadChildren: "./packages/packages.module#PackagesModule",
        canActivate: [AuthGuard],
      },
      {
        path: "notifications",
        loadChildren: "./notifications/notifications.module#NotificationModule",
        canActivate: [AuthGuard],
      },
      {
        path: "admin-notifications",
        loadChildren: "./admin-notifications/admin-notifications.module#AdminNotificationsModule",
        canActivate: [AuthGuard]
      },
      {
        path: "certificate",
        loadChildren: "./certificate/certificate.module#CertificateModule",
        canActivate: [AuthGuard],
      },
      {
        path: "orders",
        loadChildren: "./orders/orders.module#OrdersModule",
        canActivate: [AuthGuard],
      },
      {
        path: "business-hours",
        loadChildren: "./business-hours/business-hours.module#BusinessHoursModule",
        canActivate: [AuthGuard],
      },
      {
        path: "invoice",
        loadChildren: "./invoice/invoice.module#InvoiceModule",
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./pages/pages.module#PagesModule",
      },
    ],
  },
];
