import { filter } from "rxjs/operators";
import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive } from "@angular/core";
import { ADMIN_ROUTES, USER_ROUTES } from "../.././sidebar/sidebar.component";
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { Location, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { AuthenticationService , CalendarService, UserService } from "../services";

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
import { PusherService } from "app/shared/services/pusher.service";
import { NotificationModel } from "../models";
import { NotificationService } from "../services/notification.service";

declare var $: any;
import * as moment from "moment";

   

@Component({
  selector: "app-navbar-cmp",
  templateUrl: "navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;
  private _router: Subscription;
  public perPage: number = 1;
  public currentPage: number = 1; 
  public likes: any = 10;
  public notifications: NotificationModel[] = [];
  public serviceNotifications: NotificationModel[] = [];
  public serviceCount: number = 0;
  public count: number = 0;
  public workingHours: any;
  public showWorkingHours: string ='';
  public isAdmin: boolean = true;
  public setWorkingStatus: string = '';
  public offlineStatus: boolean = false;
  public userPhone: string = '';

  @ViewChild("app-navbar-cmp") button: any;

  constructor(
    location: Location,
    private renderer: Renderer,
    private element: ElementRef,
    private router: Router,
    private authService: AuthenticationService,
    private pusherService: PusherService,
    private notificationService: NotificationService,   
    private calendarService: CalendarService,
    private userService: UserService,
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function() {
        body.classList.add("sidebar-mini");

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function() {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function() {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  hideSidebar() {
    const body = document.getElementsByTagName("body")[0];
    const sidebar = document.getElementsByClassName("sidebar")[0];
    if (misc.hide_sidebar_active === true) {
      setTimeout(function() {
        body.classList.remove("hide-sidebar");
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function() {
        sidebar.classList.remove("animation");
      }, 600);
      sidebar.classList.add("animation");
    } else {
      setTimeout(function() {
        body.classList.add("hide-sidebar");
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function() {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function() {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  ngOnInit() {
    const isAdmin = this.authService.isAdmin();
    const currentUserId = this.authService.getCurrentUserId();
    if (isAdmin) {
      this.pusherService.channel.bind("admin-notification", data => {
        this.notifications = data.notifications;
        this.count = data.count;
      });

      
    } else {
      this.pusherService.channel.bind(`regular-notification-${currentUserId}`, data => {
        this.notifications = data.notifications;
        this.count = data.count;
      });
      this.showSetHours();
    }
    this.pusherService.channel.bind(`service-notification-${currentUserId}`, data => {
      this.serviceNotifications = data.notifications;
      this.serviceCount = data.count;
    });

    this.listTitles = isAdmin ? ADMIN_ROUTES.filter(listTitle => listTitle) : USER_ROUTES.filter(listTitle => listTitle);
    this.getNotifications();
    this.getServiceNotifications();
    

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains("hide-sidebar")) {
      misc.hide_sidebar_active = true;
    }
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const $layer = document.getElementsByClassName("close-layer")[0];
      if ($layer) {
        $layer.remove();
      }
    });
  }
  getNotifications(): void {
    this.notificationService.getAll().subscribe(data => {
      (this.notifications = data.rows), (this.count = data.count);
    }); 
  }

  getServiceNotifications(): void {
    this.notificationService.getAllServiceUnread().subscribe(data => {
      (this.serviceNotifications = data.rows), (this.serviceCount = data.count);
    });
  }

  readNotification(notificationId): void {
    this.notificationService.readNotification(notificationId).subscribe(() => {});
    this.router.navigate(['/notifications']);
  }
  
  onResize(event) {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function() {
      toggleButton.classList.add("toggled");
    }, 500);
    body.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    body.classList.remove("nav-open");
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const body = document.getElementsByTagName("body")[0];

    var $toggle = document.getElementsByClassName("navbar-toggler")[0];
    if (this.mobile_menu_visible == 1) {
      // $('html').removeClass('nav-open');
      body.classList.remove("nav-open");
      if ($layer) {
        $layer.remove();
      }

      setTimeout(function() {
        $toggle.classList.remove("toggled");
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function() {
        $toggle.classList.add("toggled");
      }, 430);

      var $layer = document.createElement("div");
      $layer.setAttribute("class", "close-layer");

      if (body.querySelectorAll(".main-panel")) {
        document.getElementsByClassName("main-panel")[0].appendChild($layer);
      } else if (body.classList.contains("off-canvas-sidebar")) {
        document.getElementsByClassName("wrapper-full-page")[0].appendChild($layer);
      }

      setTimeout(function() {
        $layer.classList.add("visible");
      }, 100);

      $layer.onclick = function() {
        //asign a function
        body.classList.remove("nav-open");
        this.mobile_menu_visible = 0;
        $layer.classList.remove("visible");
        setTimeout(function() {
          $layer.remove();
          $toggle.classList.remove("toggled");
        }, 400);
      }.bind(this);

      body.classList.add("nav-open");
      this.mobile_menu_visible = 1;
    }
  }

  getTitle() {
    let titlee: any = this.location.prepareExternalUrl(this.location.path());
    for (let i = 0; i < this.listTitles.length; i++) {
      if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
        return this.listTitles[i].title;
      } else if (this.listTitles[i].type === "sub") {
        for (let j = 0; j < this.listTitles[i].children.length; j++) {
          let subtitle = this.listTitles[i].path + "/" + this.listTitles[i].children[j].path;
          if (subtitle === titlee) {
            return this.listTitles[i].children[j].title;
          }
        }
      }
    }
    return "Dashboard";
  }
  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  logout(): void {
    this.authService.logout();
  }

  openTuning(notification: NotificationModel) {
    this.router.navigate(["/tuning-management", notification.tuning_id], {
      queryParams: { notification_id: notification.id },
    });
  }

  public showSetHours(): void {
    const todayTime = moment().toString();
    this.calendarService.getWorkingHours().subscribe(res => {
      this.workingHours = res;
      this.showWorkingHours = this.workingHours.start;
      this.isAdmin = false;
     
      if (todayTime > moment(this.showWorkingHours).toString() && todayTime < moment(this.workingHours.end).toString()) {
        this.setWorkingStatus = 'Engineers Online'
        this.offlineStatus = false;
      } else {
        this.offlineStatus = true;
        this.setWorkingStatus = `Engineers Offline`;
      }
    })
  }

  openWhatsApp(){
    const currentUserId = this.authService.getCurrentUserId();
    this.userService.getOne(currentUserId).subscribe(res => {
      this.userPhone = res.phone;
    })
  }

}
