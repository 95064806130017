import { UserModel } from "./user.model";
import { TuningModel } from "./tuning.model";

export class NotificationModel {
  id: string;
  text: string;
  type: string;
  user_id: number;
  tuning_id: number;
  user: UserModel;
  tuning: TuningModel;
  created_at: string;
  new: string;
}
