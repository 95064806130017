export class PackageInstanceModel {
  id: number;
  name: string;
  status: string;
  remaining_tuning: string;
  price: number;
  start_date: string;
  end_date: string;
  valid_from: string; // frontend use only
  valid_until: string;
  dealer_pro: string;
}
