import { TuningModel, PPManufacturerModel, PPCarModel, PPMotorsTuningModel } from "./index";

export class CarModel {
  id: number;
  pp_manufacturer_id: number;
  pp_car_id: number;
  pp_motors_tuning_id: number;
  pp_id: number;
  vin: string;
  other: string;
  turboloader: boolean;
  rennfilter: boolean;
  downpipes: boolean;
  ladeluftkuehler: boolean;
  auspuffanlage: boolean;
  tunings: TuningModel[];
  pp_manufacturer: PPManufacturerModel;
  pp_car: PPCarModel;
  pp_motors_tuning: PPMotorsTuningModel;
  transmission: string;
}
