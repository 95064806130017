import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_BASE_HREF } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { environment } from "../environments/environment";
import { CommonModule } from "@angular/common";
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from "@angular/material";
import { MatDatepickerModule} from "@angular/material/datepicker";

import { AuthGuard, TokenInterceptor } from "./shared";

import { AppComponent } from "./app.component";

import { SidebarModule } from "./sidebar/sidebar.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { DeleteModalComponent } from "./shared/modals/delete-modal/delete-modal.component";
import { ExtendPackageComponent } from "./shared/modals/extend-package/extend-package.component";
import { ExtendCertificateComponent } from "./shared/modals/extend-certificate/extend-certificate.component";
import { ChangePasswordComponent } from "./shared/modals/change-password/change-password.component";
import { PreviewInvoiceComponent } from "./shared/modals/preview-invoice/preview-invoice.component";

import { AppRoutes } from "./app.routing";
// import { TuningWizardComponent } from './tunings/tuning-wizard/tuning-wizard.component';

import { NgxStripeModule } from "ngx-stripe";
import { NgxPayPalModule } from "ngx-paypal";
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  declarations: [],
})
export class MaterialModule {}

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    HttpClientModule,
    MaterialModule,
    MatNativeDateModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot(`${environment.stripePublishableKey}`),
    NgxPayPalModule,
    PdfViewerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthGuard,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    DeleteModalComponent,
    ExtendPackageComponent,
    ChangePasswordComponent,
    ExtendCertificateComponent,
    PreviewInvoiceComponent
  ],
  entryComponents: [
    DeleteModalComponent, 
    ExtendPackageComponent, 
    ChangePasswordComponent, 
    ExtendCertificateComponent,
    PreviewInvoiceComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
