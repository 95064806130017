import { CarModel, UserModel, PackageInstanceModel, TuningFilesModel } from "./index";
import { FileModel } from "./file.model";

export class TuningModel {
  id: string;
  input_file: string;
  output_file: string;
  tuning_date: string;
  status: string;
  type: string;
  tool: string;
  tool_other: string;
  back_to_stock: boolean | string;
  admin_comment: string;
  car_id: number;
  user_id: number;
  free_update_expiration: string;
  car: CarModel;
  user: UserModel;
  packageinstance: PackageInstanceModel;
  estimated_finish: string;
  scheduler: any;
  files: FileModel[];
  tuning_files: TuningFilesModel[];
  chip_tuning: boolean;
  ak_47: boolean;
  gearbox: boolean;
  catless: boolean;
}
