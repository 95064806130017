import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-extend-certificate",
  templateUrl: "./extend-certificate.component.html",
  styleUrls: ["./extend-certificate.component.scss"],
})
export class ExtendCertificateComponent implements OnInit {
  certificateForm: FormGroup;
  date: string;
  text: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ExtendCertificateComponent>,
    private fb: FormBuilder
  ) {
    this.date = moment(data.valid_from).format("YYYY/MM/DD");
    this.text = data.text;
  }

  ngOnInit() {
    this.certificateForm = this.fb.group({
      validFromCtl: [new Date(this.date)],
    });
  }

  confirm() {
    let data = {
      close: true,
      valid_from: moment(this.certificateForm.controls.validFromCtl.value).format("YYYY/MM/DD"),
      type: "Certificate",
    };
    this.dialogRef.close(data);
  }
}
