import { CountryModel } from "./index";
import { PackageInstanceModel } from "./package-instance.model";

export class UserModel {
  id: number;
  email: string;
  name: string;
  address: string;
  city: string;
  country: CountryModel;
  country_id: number;
  postalcode: string;
  stripe_id: number;
  about: string;
  vat: string;
  dealer_pro: boolean;
  user_type: string;
  picture: string;
  active: string;
  invoice: string;
  token: string;
  phone: string;
  packageinstances: PackageInstanceModel;
  notification_preference: string;
  num_active_certificates: number;
}
