export class MotorModel {
  id: string;
  name: string;
  car_id: string;
  availability_turbolader: boolean = false;
  availability_rennfilter: boolean = false;
  availability_downpipes: boolean = false;
  availability_ladeluftkuehler: boolean = false;
  availability_auspuffanlage: boolean = false;
  availability_ak47: boolean = false;
  availability_gearbox: boolean = false;
}
