import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class CalendarService {
  public calendarUrl = `${environment.apiUrl}/api/calendar`;

  constructor(private httpService: HttpClient) {}

  getWeekSchedule(year: number, week: number): Observable<any> {
    return this.httpService
      .get(`${this.calendarUrl}?year=${year}&week=${week}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  createWeekSchedule(days): Observable<any> {
    return this.httpService
      .post(`${this.calendarUrl}`, days)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getWorkingHours(): Observable<any> {
    return this.httpService
      .get(`${this.calendarUrl}/workingHours`)
    .pipe(catchError((error: any) => throwError(error)));
  }

}
