import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";


@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.scss"],
})

export class DeleteModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<DeleteModalComponent>) {}

  ngOnInit() {}
  confirm() {
    this.dialogRef.close(true);
  }
}
