import { Component,Inject,OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

 @Component({
  selector: 'app-preview-invoice',
  templateUrl: './preview-invoice.component.html',
  styleUrls: ['./preview-invoice.component.scss']
})

export class PreviewInvoiceComponent implements OnInit {

   public file;

   constructor(@Inject(MAT_DIALOG_DATA) data) {
      this.file = data;
   }
   ngOnInit(): void {
   }
   
}
  
 