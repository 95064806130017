import { throwError, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CarModel, TuningModel } from "../models";
import { catchError } from "rxjs/operators";
import * as moment from "moment";
@Injectable()
export class TuningService {
  public carUrl = `${environment.apiUrl}/api/car`;
  public tuningUrl = `${environment.apiUrl}/api/tuning`;
  public productUrl = `${environment.apiUrl}/api/product`;
  public packageInstanetUrl = `${environment.apiUrl}/api/package-instance`;
  public paymentUrl = `${environment.apiUrl}/api/payment`;
  public dashboardUrl = `${environment.apiUrl}/api/dashboard`;
  public jobUrl = `${environment.apiUrl}/api/job`;

  constructor(private httpService: HttpClient) {}

  vinCheck(vin: string): Observable<any> {
    return this.httpService
      .post(`${this.carUrl}/vinCheck`, { vin: vin })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getmanufacturers(): Observable<any> {
    return this.httpService.get(`${this.carUrl}/manufacturer`).pipe(catchError((error: any) => throwError(error)));
  }

  getModels(manufacturer_id: number): Observable<any> {
    return this.httpService
      .get(`${this.carUrl}/model?manufacturer_id=${manufacturer_id}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getMotors(model_id: number): Observable<any> {
    return this.httpService
      .get(`${this.carUrl}/motor?car_id=${model_id}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  
  createCar(car: CarModel): Observable<any> {
    return this.httpService.post(`${this.carUrl}`, car).pipe(catchError((error: any) => throwError(error)));
  }

  updateCar(car: CarModel): Observable<any> {
    return this.httpService.put(`${this.carUrl}/${car.id}`, car).pipe(catchError((error: any) => throwError(error)));
  }

  createTuning(tuning): Observable<any> {
    return this.httpService.post(`${this.tuningUrl}`, tuning).pipe(catchError((error: any) => throwError(error)));
  }

  getCheckoutOptions(car_id: number, pp_id: number, back_to_stock: boolean, chip_tuning: boolean, ak_47: boolean, catless: boolean, gearbox: boolean): Observable<any> {
    return this.httpService
      .post(`${this.productUrl}/checkout-options`, {
        car_id: car_id,
        pp_id: pp_id,
        back_to_stock: back_to_stock,
        chip_tuning: chip_tuning,
        ak_47: ak_47,
        catless: catless,
        gearbox: gearbox
      })
      .pipe(catchError((error: any) => throwError(error)));
  }

  createPackage(product): Observable<any> {
    return this.httpService
      .post(`${this.packageInstanetUrl}`, { product_id: product.id, sku_id: product.sku.id })
      .pipe(catchError((error: any) => throwError(error)));
  }

  payment(payment): Observable<any> {
    return this.httpService.post(`${this.paymentUrl}`, payment).pipe(catchError((error: any) => throwError(error)));
  }

  getAllTunings(pageNumber: number, limit: number, filterObject): Observable<any> {
    let query = `?page=${pageNumber}&limit=${limit}`;
    if (filterObject && filterObject.user_id) {
      query = query + `&user_id=${filterObject.user_id}`;
    }
    if (filterObject && filterObject.vin) {
      query = query + `&vin=${filterObject.vin}`;
    }
    if (filterObject && filterObject.status) {
      query = query + `&status=${filterObject.status}`;
    }
    return this.httpService.get(`${this.tuningUrl}${query}`).pipe(catchError((error: any) => throwError(error)));
  }

  usePackage(packageinstance_id: number, tuning_id: number): Observable<any> {
    return this.httpService
      .post(`${this.packageInstanetUrl}/use`, { packageinstance_id: packageinstance_id, tuning_id: tuning_id })
      .pipe(catchError((error: any) => throwError(error)));
  }

  freePackage(car_id: number, tuning_id: number, back_to_stock?: boolean): Observable<any> {
    return this.httpService
      .post(`${this.tuningUrl}/use-free-tuning`, { car_id: car_id, tuning_id: tuning_id, back_to_stock: back_to_stock })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getPackageInstanceHistory(pageNumber: number, limit: number): Observable<any> {
    return this.httpService
      .get(`${this.packageInstanetUrl}?page=${pageNumber}&limit=${limit}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getTuningById(tuningId: number): Observable<any> {
    return this.httpService.get(`${this.tuningUrl}/${tuningId}`).pipe(catchError((error: any) => throwError(error)));
  }

  completeTuning(data): Observable<any> {
    return this.httpService.post(`${this.tuningUrl}/${data.tuning_id}`, data).pipe(catchError((error: any) => throwError(error)));
  }

  clearTuning(): Observable<any> {
    return this.httpService.delete(`${this.tuningUrl}`).pipe(catchError((error: any) => throwError(error)));
  }

  getTuningCountPerMonth(): Observable<any> {
    return this.httpService.get(`${this.tuningUrl}/month`).pipe(catchError((error: any) => throwError(error)));
  }

  getPendingTuningsCount(): Observable<any> {
    return this.httpService.get(`${this.tuningUrl}/pending`).pipe(catchError((error: any) => throwError(error)));
  }

  getCompletedTuningsCount(): Observable<any> {
    return this.httpService.get(`${this.tuningUrl}/completed`).pipe(catchError((error: any) => throwError(error)));
  }


  getActivePackage(): Observable<any> {
    return this.httpService.get(`${this.packageInstanetUrl}/active`).pipe(catchError((error: any) => throwError(error)));
  }

  getCertificateProduct(): Observable<any> {
    return this.httpService
      .get(`${this.productUrl}/certificate`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  certificatePayment(payment): Observable<any> {
    return this.httpService.post(`${this.paymentUrl}/certificate`, payment).pipe(catchError((error: any) => throwError(error)));
  }

  getTuningPerMonth(filterObjects?: any): Observable<any> {
    let query;
    if (filterObjects.user_id && filterObjects.user_id) {
      query = filterObjects.user_id != "all" ? "&user_id=" + filterObjects.user_id: "";
    }
    if (filterObjects.from && filterObjects.from) {
      query += `&from=${moment(filterObjects.from)}`
    }
    if (filterObjects.to && filterObjects.to) {
      query += `&to=${moment(filterObjects.to)}`
    }
    return this.httpService.get(`${this.dashboardUrl}/tunings-per-month?${query}`).pipe(catchError((error: any) => throwError(error)));
  }

  getTuningPerDay(): Observable<any> {
    return this.httpService.get(`${this.dashboardUrl}/tunings-per-day`).pipe(catchError((error: any) => throwError(error)));
  }

  getRevenuePerMonth(filterObjects?: any): Observable<any> {
    let query;
    if (filterObjects.user_id && filterObjects.user_id) {
      query = filterObjects.user_id != "all" ? "&user_id=" + filterObjects.user_id: "";
    }
    if (filterObjects.from && filterObjects.from) {
      query += `&from=${moment(filterObjects.from)}`
    }
    if (filterObjects.to && filterObjects.to) {
      query += `&to=${moment(filterObjects.to)}`
    }
    return this.httpService.get(`${this.dashboardUrl}/revenue-per-months?${query}`).pipe(catchError((error: any) => throwError(error)));
  }

  getTotalRevenue(): Observable<any> {
    return this.httpService.get(`${this.dashboardUrl}/total-revenue`).pipe(catchError((error: any) => throwError(error)));
  }

  verifyTuningCode(tuning_code: any): Observable<any> {
    return this.httpService.get(`${this.tuningUrl}/verify-tuning?tuning_code=${tuning_code}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getTuningFile(tuning_id: number, file_type: string): Observable<any> {
    return this.httpService.get(`${this.tuningUrl}/${tuning_id}/tuning-file?tuning_file_id=${file_type}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  checkFilesAvailability() {
    return this.httpService.post(`${this.jobUrl}/check-tuning-status`, {})
    .pipe(catchError((error: any) => throwError(error)));
  }
}
