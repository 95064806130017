import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { NotificationModel } from "app/shared";
import { PackageInstanceModel } from "../models";

@Injectable()
export class NotificationService {
  public notificationUrl = `${environment.apiUrl}/api/notification`;
  public extendTuningDaysUrl = `${environment.apiUrl}/api/job`;

  constructor(private httpService: HttpClient) {}

  getAll(): Observable<any> {
    return this.httpService.get(`${this.notificationUrl}/unread`).pipe(catchError((error: any) => throwError(error)));
  }

  removeNotifications(): Observable<any> {
    return this.httpService.delete(`${this.notificationUrl}/service`).pipe(catchError((error: any) => throwError(error)));
  }

  delete(id: any): Observable<any> {
    return this.httpService.delete(`${this.notificationUrl}/service/${id}`).pipe(catchError((error: any) => throwError(error)));
  }

  getAllServiceUnread(): Observable<any> {
    return this.httpService.get(`${this.notificationUrl}/service/unread`).pipe(catchError((error: any) => throwError(error)));
  }

  getAllWithPagination(pageNumber: number, limit: number): Observable<any> {
    return this.httpService
      .get(`${this.notificationUrl}?page=${pageNumber}&limit=${limit}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  createAdminNotification(notification: NotificationModel): Observable<any> {
    return this.httpService
    .post(`${this.notificationUrl}/service`, notification)
    .pipe(catchError((error: any) => throwError(error)));
  }

  extendTuningDays(packageInstance: PackageInstanceModel): Observable<any> {
    return this.httpService.post(`${this.extendTuningDaysUrl}`, packageInstance)
    .pipe(catchError((error: any) => throwError(error)));
  }
  
  getAllAdminNotifications(pageNumber: number, limit: number): Observable<any> {
    return this.httpService
    .get(`${this.notificationUrl}/service?limit=${limit}&page=${pageNumber}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  readNotification(notificationId: number): Observable<any> {
    return this.httpService
      .post(`${this.notificationUrl}/read`, { notification_id: notificationId })
      .pipe(catchError((error: any) => throwError(error)));
  }
}
