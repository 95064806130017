import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NavbarComponent } from "./navbar.component";
import { MatButtonModule } from "@angular/material";

import { AuthenticationService, CalendarService, NotificationService } from "../../shared";

@NgModule({
  imports: [RouterModule, CommonModule, MatButtonModule],
  providers: [AuthenticationService, NotificationService, CalendarService],
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
})
export class NavbarModule {}
