import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import { ValidationService } from "app/shared/validators";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  showError = false;
  showLengthError = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private fb: FormBuilder,
    private validationService: ValidationService,
  ) { }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      password: [null, Validators.required],
      retyped_password: [null, Validators.required],
    });
  }

  confirm() {
    this.showError = false;
    this.showLengthError = false;
    this.validationService.markFormGroupTouched(this.changePasswordForm);
    if (this.changePasswordForm.status == "VALID") {
      const password = this.changePasswordForm.controls.password.value;
      const retypedPassword = this.changePasswordForm.controls.retyped_password.value;
      if (password.length < 8) {
        this.showLengthError = true;
      } else {
        this.showLengthError = false;
        if (password === retypedPassword) {
          let data = {
            close: true,
            password: password,
          };
          this.dialogRef.close(data);
        } else {
          this.showError = true;
        }
      }
      
    }
  }
}
