// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiUrl: "http://localhost:8080",
  // publicFolder: "http://localhost:8080/public",
  apiUrl: "https://dealer.pp-performance-test.de:4530",
  publicFolder: "https://dealer.pp-performance-test.de:4530/public",
  // payPalClientId: "AbQGg-_S5UjHVX0OrQc6x_gK_DQpiw9KGJ4Tk3-CGU3Zf-Tvq1o8oeYl-I7Ip6NsVqG-zikcfJbvHtLl",
  payPalClientId: "AU5m1_QWt05PHmoHl61O6bZ3gMD3zZE2pRzyc86HPQUKIkzQ0rWwem74bDGo3Cyg7VHe4T5lDnUeqHbS",
  stripePublishableKey: "pk_test_V60RkphuJZ37fvcp7pzvlAnb",
  pusher: {
    key: "f0e7f0e0ce36ee7d18ba",
    cluster: "eu",
  },
};


