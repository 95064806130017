import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class OrderService {
  public orderUrl = `${environment.apiUrl}/api/purchaseOrder`;

  constructor(private httpService: HttpClient) {}

  getAllOrders(pageNumber: number, limit: number, synced: string): Observable<any> {
    return this.httpService
      .get(`${this.orderUrl}?page=${pageNumber}&limit=${limit}&synced=${synced}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  updateOrder(orderId: number, synced: string): Observable<any> {
    return this.httpService
      .put(`${this.orderUrl}/${orderId}`, {synced: synced})
      .pipe(catchError((error: any) => throwError(error)));
  }

}
