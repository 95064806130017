import { CarModel } from "./index";

export class PPMotorsTuningModel {
  id: number;
  car_id: number;
  car: CarModel;
  name: string;
  price_chip: string;
  availability_turbolader: boolean;
  availability_rennfilter: boolean;
  availability_downpipes: boolean;
  availability_ladeluftkuehler: boolean;
  availability_auspuffanlage: boolean;
}
