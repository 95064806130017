import { throwError, Observable } from "rxjs";

import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { UserModel } from "../models";

@Injectable()
export class AuthenticationService {
  loggedUser;

  constructor(private router: Router, private http: HttpClient) {}
  private authUrl = `${environment.apiUrl}/api/auth`;

  isAuthenticated() {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return true;
    } else {
      return false;
    }
  }

  isAdmin() {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      if (this.loggedUser.user_type === "admin") return true;
      return false;
    } else {
      return false;
    }
  }

  public getToken(): string {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser.token;
    } else {
      return null;
    }
  }

  public getCurrentUserId(): string {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser.id;
    } else {
      return null;
    }
  }

  public getCurrentUser(): UserModel {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser;
    } else {
      return null;
    }
  }

  login(data): Observable<any> {
    return this.http.post(`${this.authUrl}/login`, data).pipe(catchError((error: any) => throwError(error)));
  }

  signup(data): Observable<any> {
    return this.http.post(`${this.authUrl}/signup`, data).pipe(catchError((error: any) => throwError(error)));
  }

  logout() {
    localStorage.removeItem("current_user");
    this.router.navigate(["/login"]);
  }
}
