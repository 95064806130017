import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { UserModel } from "app/shared/models";
import * as moment from "moment";

@Component({
  selector: "app-extend-package",
  templateUrl: "./extend-package.component.html",
  styleUrls: ["./extend-package.component.scss"],
})
export class ExtendPackageComponent implements OnInit {
  userForm: FormGroup;
  date: string;
  remaining_tuning: string;
  text: string;
  user: UserModel;
  dealer_pro: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ExtendPackageComponent>,
    private fb: FormBuilder
  ) {
    this.date = moment(data.valid_until).format("YYYY/MM/DD");
    this.remaining_tuning = data.remaining_tuning;
    this.text = data.text;
    this.dealer_pro = data.dealer_pro;
  }
   
  ngOnInit() {
    this.userForm = this.fb.group({
      validUntilCtl: [new Date(this.date)],
      remainingTuningsCtl: [this.remaining_tuning],
    });
  }

  confirm() {
    
    let data = {
      close: true,
      valid_until: this.dealer_pro ? 
        moment().add('years', 100) : moment(this.userForm.controls.validUntilCtl.value).format("YYYY/MM/DD"),
      remaining_tuning: this.userForm.controls.remainingTuningsCtl.value,
      type: "Tuning",
    };
    this.dialogRef.close(data);
  }
}
 