import { Component, OnInit } from "@angular/core";
import PerfectScrollbar from "perfect-scrollbar";
import { AuthenticationService, UserModel, SidebarService, UserService } from "app/shared";
import { environment } from "../../environments/environment";

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}
export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Regular user enu Items
export const USER_ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "dashboard",
  },
  {
    path: "/user-profile",
    title: "Profile",
    type: "link",
    icontype: "person",
  },
  {
    path: "/tunings/new",
    title: "Upload file",
    type: "link",
    icontype: "cloud_upload",
  },
  {
    path: "/tunings/all",
    title: "Tuning history",
    type: "link",
    icontype: "history",
  },
  {
    path: "/packages",
    title: "Packages",
    type: "link",
    icontype: "sms",
  },
  {
    path: "/invoice",
    title: "Invoices",
    type: "link",
    icontype: "receipt",
  },
  {
    path: "/notifications",
    title: "Notifications",
    type: "link",
    icontype: "notifications",
  },
];

// Admin user menu items
export const ADMIN_ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "dashboard",
  },
  {
    path: "/user-profile",
    title: "Profile",
    type: "link",
    icontype: "person",
  },
  {
    path: "/user-management",
    title: "User management",
    type: "link",
    icontype: "supervised_user_circle",
  },
  {
    path: "/tuning-management",
    title: "Tuning Management",
    type: "link",
    icontype: "directions_car",
  },
  {
    path: "/notifications",
    title: "Notifications",
    type: "link",
    icontype: "notifications",
  },
  {
    path: "/admin-notifications",
    title: "Admin Notifications",
    type: "link",
    icontype: "notifications"
  },
  {
    path: "/orders",
    title: "Orders",
    type: "link",
    icontype: "directions_car",
  },
  {
    path: "/business-hours",
    title: "Business hours",
    type: "link",
    icontype: "directions_car",
  },
  {
    path: "/invoice",
    title: "Invoices",
    type: "link",
    icontype: "receipt",
  },
];
@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public user = new UserModel();
  publicFolderUrl: string = null;
  isOpen = false;

  constructor(
    private authService: AuthenticationService,
    private sidebarService: SidebarService,
    private userService: UserService,
  ) {
    this.publicFolderUrl = environment.publicFolder;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    const isAdmin = this.authService.isAdmin();
    const userId = this.authService.getCurrentUserId();
    this.getUser(userId);
    ADMIN_ROUTES.map(r => {
      if (r.title === "Profile") r.path = `/user-profile/${userId}`;
      return r;
    });
    USER_ROUTES.map(r => {
      if (r.title === "Profile") r.path = `/user-profile/${userId}`;
      return r;
    });
    this.menuItems = isAdmin ? ADMIN_ROUTES.filter(menuItem => menuItem) : USER_ROUTES.filter(menuItem => menuItem);
    this.sidebarService.change.subscribe(user => {
      this.user = user;
    });
    this.sidebarService.changePicture.subscribe(picture => {
      this.user.picture = picture;
    });
  }

  getUser(userId): void {
    this.userService.getOne(userId).subscribe((user: UserModel) => {
      this.user = user;
    });
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector(".sidebar .sidebar-wrapper");
      let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf("MAC") >= 0 || navigator.platform.toUpperCase().indexOf("IPAD") >= 0) {
      bool = true;
    }
    return bool;
  }
  
}
