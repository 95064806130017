import { throwError, Observable } from "rxjs";

import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { UserModel } from "app/shared";

@Injectable()
export class UserService {
  public userUrl = `${environment.apiUrl}/api/user`;
  public forgotPass = `${environment.apiUrl}/api/user/forgot-password`;
  public resetPass = `${environment.apiUrl}/api/user/forgot-password-change`;
  public packageInstanetUrl = `${environment.apiUrl}/api/package-instance`;
  public countryUrl = `${environment.apiUrl}/api/country`;
  // public declineAccount = `${environment.apiUrl}/api/user/decline-account`;
  constructor(private httpService: HttpClient) {}

  getAll(pageNumber: number, limit: number, filterObject): Observable<any> {
    let params = new HttpParams();
		let query = `?page=${pageNumber}&limit=${limit}`;

    Object.keys(filterObject).forEach(k => {
      if (filterObject[k] !== null) {
          params = params.set(k, filterObject[k]);
      }});

    return this.httpService
	 		.get(`${this.userUrl}${query}`,{params: params})
			.pipe(catchError((error: any) => throwError(error)));
  	}


	// 	searchByUserName(name:string){
	// 		return this.httpService
	// 		.get(`${this.userUrl}/search?name=${name}`)
	// 	 .pipe(catchError((error: any) => throwError(error)));
	//  }

  getOne(userId: string): Observable<any> {
    return this.httpService.get(`${this.userUrl}/${userId}`).pipe(catchError((error: any) => throwError(error)));
  }

  update(user: UserModel): Observable<any> {
    return this.httpService.put(`${this.userUrl}/${user.id}`, user).pipe(catchError((error: any) => throwError(error)));
  }

  create(user: UserModel): Observable<any> {
    return this.httpService.post(`${this.userUrl}/create-from-admin`, user).pipe(catchError((error: any) => throwError(error)));
  }

  delete(userId: number): Observable<any> {
    return this.httpService.delete(`${this.userUrl}/${userId}`).pipe(catchError((error: any) => throwError(error)));
  }

  sendPasswordOnEmail(email): Observable<any> {
    return this.httpService.post(this.forgotPass, { email: email }).pipe(catchError((error: any) => throwError(error)));
  }

  resetPassword(token, pass): Observable<any> {
    return this.httpService
      .post(this.resetPass, { reset_password_token: token, password: pass })
      .pipe(catchError((error: any) => throwError(error)));
  }

  changePassword(pass): Observable<any> {
    return this.httpService
      .post(`${this.userUrl}/change-password`, { password: pass })
      .pipe(catchError((error: any) => throwError(error)));
  }

  uploadPicture(base64Image: string, userId: number): Observable<any> {
    return this.httpService
      .put(`${this.userUrl}/${userId}/upload-picture`, { base64Image: base64Image })
      .pipe(catchError((error: any) => throwError(error)));
  }

  activateUser(token: string): Observable<any> {
    return this.httpService
      .post(`${this.userUrl}/activate-account`, { activation_code: token })
      .pipe(catchError((error: any) => throwError(error)));
  }

  removeProfilePicture(userId: number): Observable<any> {
    return this.httpService
      .put(`${this.userUrl}/${userId}/remove-picture`, {})
      .pipe(catchError((error: any) => throwError(error)));
  }

  getUserCountPerMonth(): Observable<any> {
    return this.httpService.get(`${this.userUrl}/month`).pipe(catchError((error: any) => throwError(error)));
  }

  findUsers(name: string): Observable<any> {
    return this.httpService
      .get(`${this.userUrl}/search?name=${name}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getActiveCertificate(userId: number): Observable<any> {
    return this.httpService
      .get(`${this.userUrl}/${userId}/certificate/active`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getCertificate(userId: number): Observable<any> {
    return this.httpService
      .get(`${this.userUrl}/${userId}/certificate`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getPackage(userId: number): Observable<any> {
    return this.httpService
      .get(`${this.userUrl}/${userId}/package`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  extendPackage(packageId: number, data): Observable<any> {
    return this.httpService
      .post(`${this.packageInstanetUrl}/${packageId}/extend`, data)
      .pipe(catchError((error: any) => throwError(error)));
  }

  addPackage(data): Observable<any> {
    return this.httpService
      .post(`${this.packageInstanetUrl}/addPackage`, data)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getAllCountries(): Observable<any> {
    return this.httpService
      .get(`${this.countryUrl}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  declineUserAccount(userId: number , email: string): Observable<any>{
    return this.httpService
      .post(`${this.userUrl}/${userId}/decline-account`,{email: email,}).pipe(catchError((error: any) => throwError(error)));
  }

  approveAccount(userId : number , email:string): Observable<any>{
    return this.httpService.post(`${this.userUrl}/${userId}/approve-account`, { email: email, }).pipe(catchError((error: any) => throwError(error)));
  }
}
