import { Injectable, Output, EventEmitter } from "@angular/core";
import { UserModel } from "../models";

@Injectable()
export class SidebarService {
  @Output() change: EventEmitter<UserModel> = new EventEmitter();
  @Output() changePicture: EventEmitter<string> = new EventEmitter();

  updateUser(value: UserModel): void {
    this.change.emit(value);
  }

  updatePicture(url: string): void {
    this.changePicture.emit(url);
  }
}
