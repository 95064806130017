import { UserModel } from "./index";

export class OrderModel {
  id: number;
  paypal_order_id: string;
  stripe_order_id: string;
  invoice_id: string;
  price: string;
  product_name: string;
  payment_type: string;
  synced: string;
  checked: boolean;
  user_id: string;
  user: UserModel;
}